:root {
  --principal: #436996;
  --secundaria: #4183d4;
  --background: #f3f5f5;
  --background-titulo-card: #787a7c;
}

* {
  margin: 0;
  padding: 0;
}

body {
  background-color: var(--background);
}

.sidebar {
  background-color: var(--principal);
  height: 100vh;
  min-height: 100%;
  /* max-width: 250px;
  min-width: 200px; */
  width: 15vw;
}

.sidebar-topo {
  height: 20vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sidebar-logo {
  max-width: 100%;
  object-fit: cover;
  margin: 20px;
}

.sidebar-content ul {
  padding-left: 5px;
}

.sidebar-content ul li {
  display: flex;
  align-items: center;
  text-decoration: none;
  height: 50px;
  padding-left: 20px;
  margin-top: 5px;
  margin-bottom: 5px;
  cursor: pointer;
  color: white;
  transition: 0.3s;
}

.sidebar-content ul li:hover {
  background-color: var(--secundaria);
  transform: scale(1.05);
}

.topbar {
  background-color: white;
  height: 60px;
  -webkit-box-shadow: 0px 0px 9px -6px #858585;
  box-shadow: 0px 0px 9px -6px #858585;
  width: 85vw;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.principal {
  display: flex;
  flex-direction: row;
  overflow-x: hidden;
}

.principal-content {
  display: flex;
  flex-direction: column;
}

.conteudo {
  padding: 25px;
}

.dashboard-cartao {
  color: white;
  background-color: var(--background-titulo-card);
  /* font-weight: bold; */
  /* border: none; */
}

.seletor-time-mobile {
  display: none;
}

.seletor-periodo-mobile {
  display: none;
}

.titulo-pagina {
  font-size: 18px;
}

.icone-datagrid {
  margin-left: 5;
  cursor: pointer;
  color: var(--secundaria)
}

.icone-datagrid :hover {
  color: var(--principal)
}

.menu-texto {
  font-size: 10pt;
}

.submenu-texto {
  font-size: 10pt;
}

.card-interno {
  cursor: pointer;
  width: 15rem;
  transition: 0.3s;
}

.card-interno:hover {
  transform: scale(1.15);
}

.modal-70 {
  min-width: 70%;
}

.modal-60 {
  min-width: 60%;
}

.modal-50 {
  min-width: 50%;
}



@media only screen and (max-width: 600px) {

  .menu-texto {
    display: none;
    margin-left: 0px;
    font-size: 10pt;
  }

  .submenu-icone {
    display: none;
  }

  .submenu {
    width: 240px;
    z-index: 3;
  }

  .submenu-texto {
    background-color: var(--principal);
    padding: 10px;
    z-index: 1;
  }

  .sidebar-logo {
    display: none;
  }

  .sidebar-content ul li {
    padding-left: 12px;
  }

  .seletor-time-desktop {
    display: none;
  }

  .seletor-periodo-desktop {
    display: none;
  }

  .seletor-time-mobile {
    display: block;
  }

  .seletor-periodo-mobile {
    display: block;
  }

}

@media only screen and (min-width: 600px) and (max-width: 1200px) {

  .menu-texto {
    margin-left: 0px;
    font-size: 12px;
  }

  .sidebar {
    width: 20vw;
  }

  .conteudo {
    width: 80vw;
  }

  .topbar {
    width: 80vw;
  }

  .submenu-icone {
    display: none;
  }

  .submenu {
    width: 240px;
    z-index: 3;
  }

  .submenu-texto {
    background-color: var(--principal);
    padding: 10px;
    z-index: 1;
  }

  .sidebar-content ul li {
    padding-left: 12px;
  }

  .seletor-time-mobile {
    display: none;
  }

  .seletor-periodo-mobile {
    display: none;
  }


}